import React from "react";
import PropTypes from "prop-types";

import useControlledValue from "hooks/useControlledValue";
import Box from "components/Box";
import Icon from "components/Icon";

const propTypes = {
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  step: PropTypes.number,
};

const defaultProps = {
  variant: "default",
  max: 99,
  min: 1,
  step: 1,
};

const style = {
  wrapper: {
    display: "inline-flex",
    flexFlow: "row nowrap",
    alignSelf: "start",
    alignItems: "stretch",
    justifyContent: "space-between",
    border: "1px solid",
    borderColor: "gray.200",
    borderRadius: "sm",
    textAlign: "center",
    overflow: "hidden",
    ":focus-within": {
      variant: "focus",
    },
    "[data-invalid] &": {
      borderColor: "brick.900",
    },
  },
  input: {
    display: "inline-block",
    width: "100%",
    maxWidth: "2em",
    py: 0,
    px: 0,
    m: 0,
    appearance: "none",
    fontSize: "inherit",
    lineHeight: "inherit",
    border: "none",
    color: "inherit",
    bg: "white",
    textAlign: "center",
    MozAppearance: "textfield",
    "::-webkit-inner-spin-button, ::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
    },
    ":hover:not(:focus)": {
      bg: "gray.100",
    },
    "&:active, &:focus": {
      boxShadow: "none",
      outline: "none",
    },
  },
  button: {
    display: "inline-block",
    flex: "0 0 auto",
    minWidth: "1.5em",
    minHeight: "1.5em",
    m: 0,
    py: 0,
    px: 1,
    border: "none",
    bg: "white",
    fontSize: 1,
    textAlign: "center",
    cursor: "pointer",
    outline: "none",
    appearance: "none",
    ":not(:disabled):hover": {
      backgroundColor: "gray.100",
    },
    "&:active, &:focus": {
      boxShadow: "none",
      outline: "none",
    },
    ":disabled": {
      color: "gray.200",
      cursor: "default",
    },
    svg: {
      color: "inherit",
    },
  },
};

const Counter = React.forwardRef(
  (
    {
      disabled,
      id,
      label,
      max,
      min,
      name,
      onChange,
      onInvalid,
      step,
      value: controlledValue = 1,
      variant,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useControlledValue(controlledValue);

    function handleChange(event) {
      const value = Number(event.target.value);
      if (typeof onChange === "function") {
        onChange(value);
      }
    }

    function handleInput(event) {
      const value = Number(event.target.value);
      setValue(value);
    }

    function handleIncrement() {
      setValue(prev => {
        const next = prev + 1;
        if (typeof onChange === "function") {
          onChange(next);
        }
        return next;
      });
    }

    function handleDecrement() {
      setValue(prev => {
        const next = prev - 1;
        if (typeof onChange === "function") {
          onChange(next);
        }
        return next;
      });
    }

    const styleProps = key => ({
      __css: style[key],
      __themeKey: `forms.counter`,
      variant: `${variant}.${key}`,
    });

    return (
      <Box {...styleProps("wrapper", props)}>
        {/* decrement button */}
        <Box
          aria-hidden="true"
          as="button"
          disabled={disabled || value <= min}
          onClick={handleDecrement}
          tabIndex="-1"
          type="button"
          {...styleProps("button")}
        >
          <Icon icon="minus" />
        </Box>

        {/* input */}
        <Box
          as="input"
          disabled={disabled}
          id={id}
          max={max}
          min={min}
          name={name}
          onChange={handleChange}
          onInput={handleInput}
          onInvalid={onInvalid}
          ref={ref}
          size={2}
          step={step}
          type="number"
          value={value}
          {...styleProps("input")}
        />

        {/* increment button */}
        <Box
          aria-hidden="true"
          as="button"
          disabled={disabled || value >= max}
          onClick={handleIncrement}
          tabIndex="-1"
          type="button"
          {...styleProps("button")}
        >
          <Icon icon="plus" />
        </Box>
      </Box>
    );
  }
);

Counter.propTypes = propTypes;

Counter.defaultProps = defaultProps;

export default Counter;
